import React from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'
import { DefaultSeo } from 'next-seo'
import { AnimatePresence } from 'framer-motion'
import { theme } from '@beequip/hexagon'
import '../node_modules/normalize.css/normalize.css'
import './global.css'
import * as Sentry from '@sentry/node'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    })
}

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter()

    theme.colors.buttons.primary = '#0a303c'
    theme.colors.text.default = '#0a303c'
    theme.colors.text.heading = '#0a303c'

    return (
        <ThemeProvider theme={theme}>
            <DefaultSeo
                titleTemplate="%s • watismijnmachinewaard.nl"
                openGraph={{
                    type: 'website',
                    locale: 'nl_NL',
                    url: 'https://www.watismijnmachinewaard.nl/',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    site_name: 'Wat Is Mijn Machine Waard?',
                    images: [
                        {
                            url: `${
                                process.env.VERCEL_URL ??
                                'http://localhost:3000'
                            }/android-chrome-512x512.png`,
                            width: 512,
                            height: 512,
                        },
                    ],
                }}
            />
            <AnimatePresence exitBeforeEnter>
                <Component {...pageProps} key={router.route} />
            </AnimatePresence>
        </ThemeProvider>
    )
}
